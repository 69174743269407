@import '../../styles/utils/__utils';

.heading {
  margin: 0;
  
  &:is(h1).default, &.h1{
    @include h1;
  }
  &:is(h2).default, &.h2{
    @include h2;
  }
  &:is(h3).default, &.h3{
    @include h3;
  }
  &:is(h4).default, &.h4{
    @include h4;
  }
  &:is(h5).default, &.h5{
    @include h5;
  }
  &:is(h6).default, &.h6{
    @include h6;
  }
}

