@import '../../styles/utils/__utils';

.button {
  padding: $spacing-x-small $spacing-small;
  min-width: 100px;

  cursor: pointer;

  color: $color-white;

  border: 1px solid $color-blue;
  border-radius: $border-radius-full;

  background-color: $color-blue;

  transition: 0.2s trasform;

  @include flex;
  @include body(bold);
  @media only screen and (max-width: $breakpoint-small) {
    padding: $spacing-xx-small $spacing-x-small;
  }
  .content {
    gap: $spacing-xx-small;

    @include flex;

    .sideIcon {
      display: flex;

      font-size: $font-size-medium;

      line-height: 1em;
      &:first-child {
        margin-left: calc(#{$font-size-medium} / 2 * -1);
      }
      &:last-child {
        margin-right: calc(#{$font-size-medium} / 2 * -1);
      }
    }
    .text {
      font-size: $font-size-basis;
    }
  }
  &.large {
    padding: $spacing-small $spacing-medium;
    min-width: 120px;
    @media only screen and (max-width: $breakpoint-small) {
      padding: $spacing-x-small $spacing-small;
    }
  }
  &.ghost {
    color: $color-black;

    border-color: $color-black;

    background-color: transparent;
    &.inverted {
      color: $color-white;

      border-color: $color-white;
    }
  }
  &.onlyIcon {
    padding: $spacing-x-small;
    min-width: 0px;

    font-size: $font-size-large;
    &.large {
      font-size: $font-size-x-large;
    }
    .icon {
      line-height: 1em;

      @include flex;
    }
  }
  &:disabled {
    cursor: not-allowed;

    color: $color-dark-gray;

    border-color: $color-gray;

    background-color: $color-gray;
  }
  &:hover {
    &:not(:disabled) {
      transform: scale(1);
    }
  }
}
