@import '../../styles/utils/__utils';

.app{
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .header{
    padding: $spacing-small $spacing-medium;
    background-color: $color-light-gray;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    gap: $spacing-small;
    color: $color-darker-gray;
    .mute{
      display: flex;
      justify-content: center;
      border: none;
      border-radius: 100%;
      font-size: $font-size-medium;
      width: 30px;
      height: 30px;
      color: $color-white;
      align-items: center;
      background-color: $color-blue;
      cursor: pointer;
    }
  }
  .dialogue{
    padding: $spacing-x-large $spacing-medium;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    .bubbles{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: $spacing-small;
      max-width: $text-width;

      .bubble{
        padding: $spacing-x-small $spacing-small;
        border-radius: $border-radius-large;
        opacity: 0;

        animation-iteration-count: 1;
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
        animation-name: message;
        &.delay{
          animation-delay: 0.4s;
        }
      }
      .question{
        align-self: flex-end;
        border-radius: $border-radius-large $border-radius-large 5px $border-radius-large;
        background-color: $color-blue;
        color: $color-white;
      }
      .answer{
        align-self: flex-start;
        border-radius: $border-radius-large $border-radius-large $border-radius-large 5px;
        background-color: $color-gray;

      }
    }
  }
  .form{
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: $spacing-medium;
    background-color: $color-light-gray;
    gap: $spacing-small;
    display: flex;
    input,button{
      padding: $spacing-x-small $spacing-medium;
      border-radius: $border-radius-large;
      border: none;
    }
    input{
      flex-grow: 1;
      background-color: $color-white;
      text-align: center;
      max-width: $text-width;
    }
    button{
      background-color: $color-blue;
      color: $color-white;
      cursor: pointer;
      &:disabled{
        background-color: $color-dark-gray;
        cursor: not-allowed;
      }
    }
  }
}

.loader{
  display: flex;
  align-items: center;
  height: 24px;
  gap: $spacing-xx-small;
  .ball{
    padding: 4px;
    background-color: $color-dark-gray;
    border-radius: 100%;

    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-name: ball;
    &:nth-child(1){
      animation-delay: calc((0.8s / 3) * 1);
    }
    &:nth-child(2){
      animation-delay: calc((0.8s / 3) * 2);
    }
    &:nth-child(3){
      animation-delay: calc((0.8s / 3) * 3);
    }
  }
}

@keyframes ball {
  0%{
    transform: translateY(-25%);
  }
  50%{
    transform: translateY(25%);
  }
  100%{
    transform: translateY(-25%);
  }
}

@keyframes message {
  0%{
    opacity: 0;
    transform: translateY(100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}