@import '../../styles/utils/__utils';

.main {
  position: relative;

  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
