@import '../../styles/utils/__utils';

.app {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-black;
  color: $color-white;
  *::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }
  button {
    background-color: $color-blue;
    color: $color-white;
    border: 1px solid $color-blue;
    font-size: $font-size-basis;
    padding: $spacing-x-small $spacing-small;
    border-radius: $border-radius-large;

    cursor: pointer;
    &.secondary {
      background-color: $color-white;

      color: $color-blue;
    }
    &:disabled {
      border-color: $color-dark-gray;
      background-color: $color-dark-gray;
      color: $color-white;
      cursor: not-allowed;
    }
  }
  .tickets {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $spacing-small;
    font-size: $font-size-medium;
    padding: 0;
    border: none;
  }
  .mute {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $spacing-small;
    font-size: $font-size-medium;
    padding: 0;
    border: none;
    &.muted {
      color: $color-black;
      background-color: $color-white;
    }
  }
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 30px;
    max-width: 50%;

    margin: $spacing-medium $spacing-small;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 250px $spacing-medium;

    text-align: center;
    width: 100%;
    @media only screen and (max-width: $breakpoint-small) {
      padding-top: $spacing-medium;
    }
    .loading,
    .intro {
      font-size: $font-size-large;
      @media only screen and (max-width: $breakpoint-small) {
        font-size: $font-size-medium;
      }
    }
    .result {
      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        object-position: center center;
        object-fit: cover;
      }
      .redacted {
        position: relative;
        color: $color-white;
        font-size: $font-size-large;
      }
      .quote {
        position: relative;
        color: $color-white;
        margin-bottom: $spacing-large;
        max-width: $text-width;
        font-family: 'Indie Flower', cursive;
        font-weight: bold;
        font-size: $font-size-xx-large;
        transform: rotate(-3deg);
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
        @media only screen and (max-width: $breakpoint-small) {
          font-size: $font-size-large;
        }
      }
      .actions {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacing-x-small;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: $spacing-xx-small;
        }
      }
    }
  }
  .prompt {
    position: fixed;
    bottom: 0;
    margin: 0 $spacing-small;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: $spacing-medium;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: $spacing-large;
    padding-bottom: $spacing-x-small;
    border-radius: $border-radius $border-radius 0 0;
    background-color: $color-white;
    transition: 0.5s;
    max-width: 600px;
    transform: translateY(100%);
    &:not(.hidden) {
      transform: translateY(0%);
    }
    .input {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-size: $font-size-medium;
      gap: $spacing-x-small;
      color: $color-black;
      text-align: center;
      @media only screen and (max-width: $breakpoint-small) {
        font-size: $font-size-basis;
      }
      input {
        border: none;
        text-align: center;
        max-width: 150px;
        background-color: transparent;
        border-bottom: 1px solid $color-black;
        outline: none;
        border-radius: 0;
      }
    }
    .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: $spacing-small;
    }
    .disclaimer {
      color: $color-dark-gray;
      line-height: 1.1em;
      text-align: center;
      font-size: $font-size-x-small;
      a {
        text-decoration: underline;
      }
    }
  }
}
