@import '../../styles/utils/__utils';

.background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 1s;
}
.content{
  @include flex(center, center, column);
  gap: $spacing-small;
  padding: $spacing-large;
  text-align: center;
  color: $color-black;
  transition: 1s;
  .query{
    text-transform: capitalize;
  }
  input,button{
    padding: $spacing-x-small $spacing-medium;
    border-radius: $border-radius-large;
    border: none;
  }
  input{
    background-color: $color-light-gray;
    border: 1px solid $color-gray;
    //box-shadow: 0 0 $spacing-small 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    text-transform: capitalize;
    &::placeholder{
      text-transform: none;
    }
  }
  button{
    background-color: $color-black;
    color: $color-white;
    cursor: pointer;
  }

  &.inverted{
    color: $color-white;
    button{
      background-color: $color-white;
      color: $color-black;
    }
  }
}
.count{
  position: absolute;
  bottom: 0;
  padding: $spacing-x-small;
  color: $color-dark-gray;
  @include caption;
  &.inverted{
    color: $color-light-gray;
  }
}