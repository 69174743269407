@import '../../styles/utils/__utils';

.app {
  position: absolute;
  width: 100%;
  min-height: 100%;
  padding: $spacing-large;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: $spacing-xx-large;
  text-align: center;
  overflow: hidden;

  header {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: $spacing-x-small $spacing-small;
    display: flex;
    align-items: center;
    gap: $spacing-xx-small;
    justify-content: space-between;
    font-size: $font-size-x-small;
    .open {
      z-index: 10;
    }
  }

  .panel {
    position: absolute;
    width: 30%;
    max-width: $text-width;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-light-gray;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
    }
    &:not(.open) {
      transform: translateX(100%);
    }
    p {
      color: $color-dark-gray;
    }
    .list {
      position: absolute;
      max-height: 100%;
      width: 100%;
      padding: $spacing-xx-large $spacing-small;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      gap: $spacing-xx-small;
      span {
        font-size: $font-size-basis;
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: $spacing-x-small;
  }
  .generator {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $spacing-medium;
  }

  .result {
    .name {
      text-transform: capitalize;
      font-size: 120px;
      width: 100%;
      line-height: 1em;
      margin-bottom: $spacing-medium;
      word-break: break-all;
      @media only screen and (max-width: $breakpoint-medium) {
        font-size: $font-size-xx-large;
      }
    }
    p {
      font-family: monospace;
      margin: auto;
      width: 100%;
      max-width: 400px;
      text-align: center;
      //font-style: italic;
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: $spacing-x-small;
    font-size: $font-size-x-small;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacing-medium;
    text-align: center;
    a,
    span {
      text-decoration: underline;
    }
  }

  &.dark {
    color: white;
    background: black;
    .panel {
      background-color: rgb(26, 26, 26);
    }
  }
}
