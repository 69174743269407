@import '../../styles/utils/__utils';

.links{
  margin-top: $spacing-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $spacing-x-small;
  a{
    font-size: $font-size-medium;
    text-decoration: underline;
    cursor: pointer;
  }
}