@import '../../styles/utils/__utils';

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-large;
  background-color: $color-black;
  color: $color-white;
}
